<template>
  <login-layout>
    <template slot="body">
      <div class="forgot-password-screen">
        <div class="gradient-bg-section" />
        <div class="forgot-password-screen__inner">
          <div class="titles-section">
            <span class="title">
              {{ $t('pages.forgotPassword.title') }}
            </span>
            <span class="subtitle">
              {{ subtitle }}
            </span>
          </div>
          <div
            class="forgot-password-screen__main"
            v-if="!sent"
          >
            <VerticalTitleInput
              :title="$t('pages.forgotPassword.inputs.email.value')"
              :placeholder="$t('pages.forgotPassword.inputs.email.placeholder')"
              :value="email"
              :valid="this.validEmail"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('email', e)"
            />
            <BaseButton
              large
              rectangular
              :label="$t('pages.forgotPassword.button')"
              class="forgot-password-screen__button"
              @click="handleForgotPassword"
            />
          </div>
        </div>
      </div>
    </template>
  </login-layout>
</template>
<script>
import { VerticalTitleInput, BaseButton } from 'ff-components-lib';
import LoginLayout from '@/components/layout/login/LoginLayout';

export default {
  name: 'ForgotPasswordScreen',
  components: {
    LoginLayout,
    VerticalTitleInput,
    BaseButton,
  },
  data() {
    return {
      email: undefined,
      sent: false,
    };
  },
  computed: {
    validEmail() {
      return (
        this.email === undefined
        || (typeof this.email === 'string' && this.email.length > 0)
      );
    },
    validFields() {
      return this.email && this.validEmail;
    },
    subtitle() {
      return this.sent
        ? this.$t('pages.forgotPassword.sentSubtitle')
        : this.$t('pages.forgotPassword.subtitle');
    },
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
    },
    resetFields() {
      this.email = undefined;
    },
    async handleForgotPassword() {
      if (!this.validFields) {
        this.email = this.email || '';
      } else {
        try {
          await this.$store.dispatch('forgotPassword', {
            email: this.email,
          });

          this.sent = true;
          this.resetFields();
        } catch (e) {
          await this.$store.dispatch('displayAlert', {
            text: this.$t('components.alerts.error'),
            type: 'error',
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.forgot-password-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 196px);

  .gradient-bg-section {
    position: absolute;
    width: 100%;
    height: 80%;
    background: linear-gradient(
      180deg,
      rgba($primary, 0.3) 0%,
      rgba($primary, 0) 100%
    );
    top: 100px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 48px;
    z-index: 10;
    margin-top: 220px;
    margin-bottom: 220px;

    .titles-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 16px;

      .title {
        font-size: 36px;
        font-weight: 600;
        letter-spacing: -0.72px;
      }

      .subtitle {
        color: $grey;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .forgot-password-screen__main {
      display: flex;
      flex-direction: column;
      gap: 32px;

      ::v-deep .base-input {
        box-shadow: 0px 4px 10px 0px rgba($primary, 0.1);
      }

      .forgot-password-screen__button {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 970px) {
  .forgot-password-screen {
    &__inner {
      width: 76%;
      gap: 40px;
      margin-top: 160px;
      margin-bottom: 160px;
    }
  }
}

@media only screen and (max-width: 770px) {
  .forgot-password-screen {
    min-height: calc(100vh - 174px);

    .gradient-bg-section {
      top: 70px;
    }

    &__inner {
      width: 84%;
      gap: 40px;
      margin-top: 90px;
      margin-bottom: 90px;

      .titles-section {
        gap: 8px;

        .title {
          font-size: 24px;
          letter-spacing: -0.48px;
        }

        .subtitle {
          font-size: 16px;
        }
      }

      .forgot-password-screen__main {
        gap: 24px;

        .forgot-password-screen__button {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
